footer.sticky-footer {
    padding: 2rem 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    background-color: red;
  }
  
  footer.sticky-footer .copyright {
    line-height: 1;
    font-size: 0.8rem;
  }
  
  body.sidebar-toggled footer.sticky-footer {
    width: 100%;
  }
  